import React from "react"

import { graphql, StaticQuery } from "gatsby"

import styles from "../../../components/modules/image_text_block/index.module.scss"
import ContentBoundary from "../../../components/content/boundary"
import StoryNewsListing from "../../modules/story_news_listing"

const WaterStoryListings = () => (
  <StaticQuery
    query={graphql`
      query waterStoryQuery {
        allDatoCmsAbout {
          edges {
            node {
              # For articles
              waterStories {
                id
                title
                slug
                storyListing
                modules {
                  __typename
                  ... on DatoCmsHero {
                    id
                    heroTitle
                    backgroundImage {
                      url
                      alt
                    }
                  }
                  __typename
                  ... on DatoCmsBodyCopy {
                    bodyCopyNode {
                      childMarkdownRemark {
                        excerpt
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    // tslint:disable-next-line: jsx-no-lambda
    render={(data) => {
      const allListings = data.allDatoCmsAbout.edges

      const renderAllListings = allListings.map(({ node }) => {
        let i = 1
        return (
          // tslint:disable-next-line: jsx-key
          <section
            key={i}
            className={`${styles.container} ${styles.containerWithBackground} util__padding-top--20 `}
          >
            <div className={styles.containerWithBackgroundRowTop}>
              <div className={styles.withBackground__topWave} />
            </div>

            <div className={styles.withBackground__contentRow}>
              <div className="util__text-align--center util__margin-bottom--55">
                <h2 className="typography__display--6">Water Stories</h2>
              </div>

              <ContentBoundary>
                <div className="grids__listing util__padding-bottom--20">
                  {node.waterStories.map(
                    ({ id, title, storyListing, slug, modules }) => {
                      const bodyCopy = modules.filter(
                        ({ __typename }) => __typename === "DatoCmsBodyCopy"
                      )

                      const thumbnail = modules.filter(
                        ({ __typename }) => __typename === "DatoCmsHero"
                      )

                      const handleThumbnail = () => {
                        if (thumbnail.length > 0) {
                          return thumbnail[0].backgroundImage
                        } else {
                          return
                        }
                      }

                      const handleHasImage = () => {
                        if (thumbnail.length > 0) {
                          return true
                        } else {
                          return false
                        }
                      }

                      return (
                        <StoryNewsListing
                          key={id}
                          excerpt={
                            bodyCopy[0].bodyCopyNode.childMarkdownRemark.excerpt
                          }
                          slug={`/stories-and-news/${slug}`}
                          title={title}
                          image={handleThumbnail()}
                          ctaLabel={`label`}
                          isStoryListing={storyListing}
                          hasCta={true}
                          hasImage={handleHasImage()}
                        />
                      )
                    }
                  )}
                </div>
              </ContentBoundary>
            </div>
            <div className={styles.containerWithBackgroundRowBtm}>
              <div className={styles.withBackground__bottomWave} />
            </div>
          </section>
        )
        i + 1
      })

      return renderAllListings
    }}
  />
)
export default WaterStoryListings
