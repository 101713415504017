import { graphql } from "gatsby"
import React from "react"
import Head from "../../components/global/head"

import LogoGrid from "../../components/logo_grid"
import HeroIntroBanner from "../../components/modules/hero_intro_banner"
import ModuleRenderer from "../../components/modules"
import CtaBanner from "../../components/modules/cta_banner"

import WaterStoryListing from "../../components/content/water_story_listings"

const AboutUs = ({ data }) => {
  const aboutHero = data.allDatoCmsAbout.edges[0].node.module.filter(
    (module) => module.__typename === "DatoCmsHero"
  )

  const { ctaBannerModule, showLogos } = data.allDatoCmsAbout.edges[0].node

  return (
    <>
      <Head
        title="About Us"
        url="https://www.choosetap.com.au/about-us"
        description="Choose Tap aims to improve the health and wellbeing of communities and the environment by promoting tap water as the best choice of hydration for all Australians."
        keywords="what is choose tap, about choose tap, choose tap, tap water australia, what is refillers welcome, what is refillers, drinking water australia, drinking water"
        bodyClassname="body__about"
      />
      <section className="pages__child">
        <HeroIntroBanner
          className="aboutUsLanding"
          title={aboutHero[0].heroTitle}
          shortDescription={aboutHero[0].heroText}
          sectionName="About Us"
          themeColor="grey"
          image={null}
          hasImage={false}
        />
        <main>
          <ModuleRenderer
            isLandingInstance={false}
            modules={data.allDatoCmsAbout.edges[0].node.module}
          />

          {showLogos && <LogoGrid isPartnerLogos={true} showLogosOnly={true} />}
          <WaterStoryListing />
          {ctaBannerModule.length > 0 && (
            <CtaBanner
              isFooterInstance={true}
              title={ctaBannerModule[0].title}
              ctaLabel={ctaBannerModule[0].ctaLabel}
              ctaLink={ctaBannerModule[0].ctaLink}
              ctaPageUrl={ctaBannerModule[0].ctaPageUrl}
              image={ctaBannerModule[0].backgroundImage}
            />
          )}
        </main>
      </section>
    </>
  )
}

export default AboutUs

export const query = graphql`
  query AboutUsQuery {
    allDatoCmsAbout {
      edges {
        node {
          showLogos
          ctaBannerModule {
            title
            backgroundImage {
              url
            }
            ctaLink {
              __typename
              ... on DatoCmsPost {
                slug
              }
              __typename
              ... on DatoCmsProgram {
                slug
              }
            }
            ctaLabel
            ctaPageUrl
          }
          module {
            __typename
            ... on DatoCmsHero {
              heroTitle
              heroText
              backgroundImage {
                url
              }
            }
            __typename
            ... on DatoCmsTwoColumnTextBlock {
              column1
              column2
            }
          }
        }
      }
    }
  }
`
